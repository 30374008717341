<template>

  <section id="card-text-alignment">
    <!-- Arama Bölümü -->
    <section id="search-section">
      <b-row>
        <b-col md="12">
          <b-card class="mt-1 mb-1">
            <b-overlay
                :show="show"
                rounded="sm"
            >
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="10">
                    <b-col>
                      <b-form-input placeholder="Takip No" type="text" size="lg" @keyup.enter="setTrackingNumber"/>
                    </b-col>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Yönetim">
                      <b-button-group size="sm">
                        <!-- Butonlar Buraya Gelecek -->
                        <b-button variant="primary" @click="getAll">Getir</b-button>
                      </b-button-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-card class="mt-1 mb-1">
            <b-row>
              <b-col md="2" v-for="(statistic,index) in _statistics" :key="index">
                {{ statistic.name }}<br>
                <b-badge variant="success">{{ statistic.okCount }}</b-badge>
                -
                <b-badge variant="warning">{{ statistic.count }}</b-badge>
              </b-col>

            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <AgreementDetailList></AgreementDetailList>

  </section>

</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AgreementDetailList from "@/views/agreement/detail/AgreementDetailList";
import {mapGetters} from "vuex";
import soundMixins from "@/mixins/soundMixins";

export default {
  name: "AgreementDetailHome",
  components: {AgreementDetailList},
  mixins: [soundMixins],
  computed: {
    ...mapGetters("agreementDetail", ["_statistics"]),
  },
  data: () => ({
    show: false,
  }),
  methods: {
    getAll() {
      this.$store.dispatch('agreementDetail/getAll').then(res => {
        this.$toast({
          component: ToastificationContent,
          props: res
        })
      })
    },
    setTrackingNumber($event) {
      let number = $event.target.value;
      $event.target.value = "";
      this.$store.dispatch('agreementDetail/setTrackingNumber', {search_tracking_number: number})
          .then(res => {
            this.autoPlay(res.variant)
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    }
  }
}
</script>

<style scoped>

</style>

