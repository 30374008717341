<template>
  <b-card-code title="Kargo Paket Takip Ekranı">
    <vue-good-table
        ref="agreement-detail-table"
        :columns="columns"
        :rows="_items"
        :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: ' adet paket seçildi',
        clearSelectionText: 'Temizle',
        selectAllByGroup: true,
      }"
        :pagination-options="{enabled: true,perPage:pageLength}"
        :sort-options="{enabled: true,initialSortBy: {field: 'created_at', type: 'desc'}
  }"
    >
      <div slot="selected-row-actions">
        <b-button variant="success" @click="setAgreement">Mutabakat Yap</b-button>
      </div>


      <template
          slot="table-row"
          slot-scope="props"
      >
        <span v-if="props.column.field == 'search_tracking_number1'">
            <b-badge>{{ props.row.search_tracking_number }}</b-badge>
          <div v-if="props.row.order">
            <div v-if="props.row.order.tracking">
              <b-badge
                  v-if="props.row.search_tracking_number!=props.row.order.tracking.tracking_number"
                  :variant="props.row.search_tracking_number!=props.row.order.tracking.tracking_number?'danger':''"
              >
                {{ props.row.order.tracking.tracking_number }}</b-badge>
            </div>
          </div>
        </span>

        <span v-if="props.column.field == 'created_at'">
           <ViewDate :date="props.row.created_at" name=""></ViewDate>
        </span>

        <b-badge :variant="statusVariant(props.row.order.status.id)"
                 v-else-if="props.column.field == 'order.status.status'">
          {{ props.row.order.status.status }}
        </b-badge>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>


      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select
                v-model="pageLength"
                :options="['1','10','101']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {mapGetters} from "vuex";
import ViewDate from "@/views/common/ViewDate";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ViewDate
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Takip No',
          field: 'search_tracking_number1',
          filterOptions: {
            enabled: true,
            placeholder: 'Takip No',
          },
        },
        {
          label: 'Mağaza',
          field: 'order.store.store_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Mağaza Adı',
            filterDropdownItems: ['Myshop', 'Fırsat Saati', 'mhazi', 'Gz Ticaret', 'Mybook', 'HızlıKargo'],
          },
        },
        {
          label: 'Sipariş No',
          field: 'order_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Sipariş No',
          },
        },
        {
          label: 'Kargo Firma',
          field: 'order.tracking.company.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Kargo Firması',
            filterDropdownItems: ['Aras', 'DHL', 'Diğer', 'ETD', 'Hepsijet', 'KolayGelsin', 'Sürat', 'Trendyol Express', 'Yurtiçi', 'MNG Kargo'],
          },
        },

        {
          label: 'Ekleme Tarihi',
          field: 'created_at',
          filterOptions: {
            enabled: true,
            placeholder: 'ekleme Tarihi',
          },
        },
        {
          label: 'Sipariş Durumu (PY)',
          field: 'order.order_status',
          filterOptions: {
            enabled: true,
            placeholder: 'Sipariş Durumu',
          },
        },
        {
          label: 'Sipariş Durumu',
          field: 'order.status.status',
          filterOptions: {
            enabled: true,
            placeholder: 'Sipariş Durumu',
          },
        },
        {
          label: 'Müşteri',
          field: 'order.customer_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Müşteri Adı',
          },
        },
        {
          label: 'Kullanıcı',
          field: 'user.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Ekleyen',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters("agreementDetail", ["_items"]),
    statusVariant() {
      const statusColor = {
        0: 'dark',
        1: 'dark',
        2: 'dark',
        3: 'dark',
        4: 'dark',
        5: 'dark',
        6: 'success',
        7: 'success',
        8: 'dark',
        9: 'dark',
        10: 'dark',
        11: 'dark',
        12: 'dark',
      }
      return status => statusColor[status]
    },

  },
  methods: {
    getAll() {
      this.$store.dispatch('agreementDetail/getAll')
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    },
    setAgreement() {
      let agreementsId = [];
      let agreements = this.$refs['agreement-detail-table'].selectedRows;
      let cargoCompany, isError = false;
      agreements.forEach(agreement => {
        agreementsId.push(agreement.id);
        if (!cargoCompany) cargoCompany = agreement.order.tracking.company.name;
        if (cargoCompany != agreement.order.tracking.company.name) {
          isError = true;
        }
      })

      if (isError) {
        alert('Birden çok kargo ile işlem yapamazsın')
        return;
      }
      this.$store.dispatch('agreementDetail/setAgreement', {ids: agreementsId})
          .then(res => {
            this.getAll();
            this.$toast({
              component: ToastificationContent,
              props: res
            })
          })
    }
  },
  created() {
    this.getAll();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
